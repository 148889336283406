const flow = {
  namespaced: true,
  state: {
    flowModel: 'normal', // normal: 标准模式 systematic: 体系化模式
    page: {
      width: 0,
      height: 0,
      top: 60,
      left: 250,
      right: 250,
      scale: 1
    },
    svgRoot: {
      top: 0,
      left: 0,
      right: 0,
      scrollLeft: 0,
      scrollTop: 0,
    },
    lanes: {
      vertical: [],
      horizontal: [],
      height: 30,
      width: 30,
    },
    LaneDirection: {
      vertical: "vertical",
      horizontal: "horizontal"
    },
    allPoints: {},
    selectedList: [],
    lineDefault: {
      Path: {
        id: "path",
        strokeWidth: "2",
        arrow: "block-wide-long",
        stroke: "#999",
        hoverStrokeWidth: "3",
        hoverStroke: "#990033"
      },
      Lines: {
        Line_0: {
          name: "一般线",
          stroke: "#999"
        },
        Line_1: {
          name: "汇总线",
          stroke: "#1E7EFF"
        },
        Line_2: {
          name: "退回线",
          stroke: "#F24B35"
        }
      }
    },
    checked: null
  },
  mutations: {
    setFlowModel(state, model) {
      state.flowModel = model;
      state.page = Object.assign(state.page, {
        right: model === 'normal' ? 0 : 250
      });
    },
    setPage(state, options) {
      state.page = Object.assign(state.page, options);
    },
    setSvgRoot(state, options) {
      state.svgRoot = Object.assign(state.svgRoot, options);
    },
    setChecked(state, checked) {
      state.checked = checked;
    },
    addLane(state, options) {
      if (options.index > -1) {
        state.lanes[options.direction].splice(options.index, 0, options.lane);
      } else {
        state.lanes[options.direction].push(options.lane);
      }
      this.commit('flow/reloadLanes', options.direction);
    },
    deleteLane(state, checked) {
      const index = state.lanes[checked.direction].indexOf(checked);
      if (index > -1) {
        state.lanes[checked.direction].splice(index, 1);
      }
      this.commit('flow/reloadLanes', checked.direction);
    },
    changeLane(state, lane) {
      state.lanes[lane.direction].forEach((item) => {
        if (item.id === lane.id) {
          item = Object.assign(item, lane);
        }
      });
      this.commit('flow/reloadLanes', lane.direction);
    },
    reloadLanes(state, direction) {
      let before;
      if (direction === state.LaneDirection.vertical) {
        state.lanes[direction].forEach((lane) => {
          lane.left = before ? before.size + before.left : 0;
          before = lane;
        });
      } else {
        state.lanes[direction].forEach((lane) => {
          lane.top = before ? before.size + before.top : 0;
          before = lane;
        });
      }
    },
    clearLanes(state) {
      state.lanes.vertical = [];
      state.lanes.horizontal = [];
    },
    setPointsByNode(state, { node, obj } = options) {
      // 一般节点/自动流 暂定横向8个点 纵向3个点 如不够 后面修改
      let points = [];
      // if (node.sort === 'task' || obj.sort === "attached") {
      let nodeWidth, nodeHeight;
      const hide = node.open && !node.open.show;
      nodeWidth = hide ? node.open.width : node.width;
      nodeHeight = hide ? node.open.height : node.height;
      // x轴能放的个数
      const xAll = parseInt((nodeWidth + obj.width) / obj.width);
      // 计算x轴每个之间的间距
      const xPadding = parseInt((nodeWidth + obj.width) % obj.width / (xAll > 2 ? (xAll - 2) : 1));
      // y轴暂不让放置
      const yAll = 0;
      // y轴能放的个数
      // const yAll = parseInt((nodeHeight + obj.height) / obj.height);
      // 计算y轴每个之间的间距
      const yPadding = parseInt((nodeHeight + obj.height) % obj.height / (yAll > 2 ? (yAll - 2) : 1));
      // 计算横轴的坐标
      for (let i = 0; i < xAll; i++) {
        // 上面坐标
        const top = {
          point: {
            x: (node.point.x + i * xPadding + obj.width * (i - 1 / 2)) * state.page.scale,
            y: (node.point.y - obj.img.width * 1 / 2) * state.page.scale,
          },
          index: i,
          direction: 'top',
        }
        const bottom = {
          point: {
            x: (node.point.x + i * xPadding + obj.width * (i - 1 / 2)) * state.page.scale,
            y: (node.point.y + nodeHeight - obj.img.width * 1 / 2) * state.page.scale,
          },
          index: i,
          direction: 'bottom',
        }
        if (i === xAll - 1) {
          top.isMax = true;
          bottom.isMax = true;
        }
        points.push(top);
        // 下面坐标
        points.push(bottom);
      }

      // 计算横轴的坐标 舍弃四个角落的点
      for (let j = 1; j < yAll - 1; j++) {
        // 左边坐标
        const left = {
          point: {
            x: (node.point.x - obj.height * 1 / 2) * state.page.scale,
            y: (node.point.y + yPadding + obj.height * (j - 1 / 2)) * state.page.scale,
          },
          index: j,
          direction: 'left',
        }
        // 右边坐标
        const right = {
          point: {
            x: (node.point.x + nodeWidth - obj.height * 1 / 2) * state.page.scale,
            y: (node.point.y + yPadding + obj.height * (j - 1 / 2)) * state.page.scale,
          },
          index: j,
          direction: 'right',
        }
        if (j === yAll - 2) {
          left.isMax = true;
          right.isMax = true;
        }
        points.push(left);
        points.push(right);
      }
      // }
      // return points;
      state.allPoints[node.id] = points;
    },
    addPoint(state, { id, obj } = options) {
      state.allPoints[id] && state.allPoints[id].push(obj);
    },
    deletePoint(state, { id, index } = options) {
      state.allPoints[id] && state.allPoints[id].splice(index, 1);
    },
    clearPoints(state) {
      state.allPoints = {};
    },
    setSelectedList(state, list) {
      state.selectedList = list;
    },
    addSelected(state, item) {
      state.selectedList.push(item);
    },
    deleteSelected(state, index) {
      index > -1 && state.selectedList.splice(index, 1);
    },
  },
  actions: {},
  getters: {
    lanes: (state, direction) => {
      return state.lanes[direction];
    },
    lanesWidth: (state) => {
      return state.lanes.horizontal.length > 0 ? state.lanes.width : 0;
    },
    lanesHeight: (state) => {
      return state.lanes.vertical.length > 0 ? state.lanes.height : 0;
    },
    getMousePointByCanvas: (state, store) => (mouseEvent) => {
      let x = mouseEvent.x;
      let y = mouseEvent.y;
      let svgRootOffset = store.getSvgOffset;
      x = x - state.page.left - svgRootOffset.left + state.svgRoot.scrollLeft;
      y = y - state.page.top - svgRootOffset.top + state.svgRoot.scrollTop;
      return {
        x: x,
        y: y,
      };
    },
    getSvgOffset: (state, store) => {
      const left = store.lanesWidth;
      const top = store.lanesHeight;
      const svgRootOffset = {
        top: Math.abs(parseFloat(top || 0)),
        left: Math.abs(parseFloat(left || 0)),
      };
      return svgRootOffset;
    },
    getMousePointByPoint: (state, store) => (point) => {
      let x = point.x;
      let y = point.y;
      let svgRootOffset = store.getSvgOffset;
      x = x + state.page.left + svgRootOffset.left - state.svgRoot.scrollLeft;
      y = y + state.page.top + svgRootOffset.top - state.svgRoot.scrollTop;
      return {
        x: x,
        y: y,
      };
    },
    getPoints: (state) => (id) => {
      return state.allPoints[id];
    },
  }
}

export default flow