// import dsf from '../utils';
export default function(dsf) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [{
      name: "文本",
      code: "text",
      type: dsf.metadata.getDataType("string"),
      length: 100,
      defaultValue: null,
      //是否为空验证字段
      emptyValidate: true,
      unit: null,
      encrypt:false
    }]
  };
}