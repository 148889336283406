const mobileMailChecked = {
  namespaced: true,
  state: {
    checked: {},
    otherpageid: null
  },
  mutations: {
    updateChecked(state, checked) {
      state.checked = _.cloneDeep(checked);
    },
    updateOtherpageid(state, otherpageid) {
      state.otherpageid = otherpageid;
    }
  }
}
  
export default mobileMailChecked;