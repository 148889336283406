/*用于移动端更新keepAlive缓存页面*/
const keepAlive = {
  namespaced: true,
  state: {
    include: [],
    exclude: [],
    context: []
  },
  mutations: {
    add(state, context) {
      dsf.array.ensure(state.context, context, (v) => {
        return v.path == context.path;
      });
      state.include = _.map(state.context, "name");
      //如果include中存在则在exclude中删除掉
      _.each(state.include, (v) => {
        dsf.array.remove(state.exclude, v);
      })
    },
    clear(state) {
      state.context = [];
      state.include = [];
      state.exclude = [];
    },
    remove(state, path) {
      let names = [];
      dsf.array.removeWith(state.context, (v) => {
        if (v.path == path) {
          if (v.vm) {
            v.vm = null;
          }
          names.push(v.name);
          return true;
        }
      });
      state.include = _.map(state.context, "name");
      _.each(names, (n) => {
        dsf.array.ensure(state.exclude, n)
      })
    }
  },
  actions: {},
  getters: {
    instances: state => {
      return _.keyBy(state.context, "path");
    }
  }
}

export default keepAlive