export default function(dsf, options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    dataSource: {
      type: "static",
      code: "",
      attach: [],
    },
    valueAttributes: [{
      name: "文本",
      code: "text",
      type: dsf.metadata.getDataType("string"),
      length: "200",
      defaultValue: null,
      encrypt:false,
      unit: null,
    },
    {
      name: "值",
      code: "value",
      type: dsf.metadata.getDataType("string"),
      length: "200",
      defaultValue: null,
      encrypt:false,
      unit: null
    }
    ],
  };
}