import refreshQueue from "./refreshQueue";
import flow from "./flow";
import seatDesign from "./seatDesign";
import keepAlive from './keepAlive';
import mobileMailChecked from "./mobileMailChecked";
import printingDesign from './printingDesign';
import userInfoWaterMark from './userInfoWaterMark'
import ws from './ws';

export default {
  refreshQueue,
  flow,
  seatDesign,
  keepAlive,
  mobileMailChecked,
  printingDesign,
  userInfoWaterMark,
  ws
};
