export default function (dsf,options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(2),
    at: "",
    currentControl: null,
    controls: [],
    level: 0,
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [
      {
        name: "",
        code: "",
        type: "",
        length: "",
        defaultValue: null,
        unit: null,
        encrypt:false
      },
    ],
  };
}
