export default function(dsf,options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [
      {
        name: "日期",
        code: "date",
        type: dsf.metadata.getDataType("date"),
        length: null,
        defaultValue: null,
        unit: null,
        encrypt:false
      }
    ]
  };
}