const userInfoWaterMark = {
  namespaced: true,
  state: {
    show: false
  },
  mutations: {
    on(state) {
      state.show = true;
    },
    off(state) {
      state.show = false;
    }
  }
}

export default userInfoWaterMark