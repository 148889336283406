export default function(dsf) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [
      {
        name: "地点",
        code: "address",
        type: dsf.metadata.getDataType("string"),
        length: 200,
        defaultValue: null,
        emptyValidate: true,
        encrypt:false,
        unit: null
      },
      {
        name: "建筑名称",
        code: "title",
        type: dsf.metadata.getDataType("string"),
        length: 60,
        defaultValue: null,
        emptyValidate: true,
        encrypt:false,
        unit: null
      },
      {
        name: "经度",
        code: "lng",
        type: dsf.metadata.getDataType("string"),
        length: 20,
        defaultValue: null,
        emptyValidate: true,
        encrypt:false,
        unit: null
      },
      {
        name: "纬度",
        code: "lat",
        type: dsf.metadata.getDataType("string"),
        length: 20,
        defaultValue: null,
        emptyValidate: true,
        encrypt:false,
        unit: null
      },
    ]
  };
}