/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-23 14:06:16
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-12-23 14:07:30
 */
export default function(dsf, options){
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [{
      name: "签名上传",
      code: "file",
      type: dsf.metadata.getDataType("object"),
      length: 500,
      defaultValue: null,
      unit: null,
      encrypt:false
    }]
  };
}
