/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-11-16 10:03:41
 * @LastEditors: zhanghang
 * @LastEditTime: 2021-12-23 14:08:43
 */
import bigTextMetaData from "./bigTextMetaData";
import booleanMetaData from "./booleanMetaData";
import customerImgMetaData from "./customerImgMetaData";
import dataChoiceMetaData from './dataChoiceMetaData';
import dateIntervalMetaData from "./dateIntervalMetaData";
import datePickerMetaData from "./datePickerMetaData";
import examItemsMetaData from "./examItemsMetaData";
import examMetaData from "./examMetaData";
import examSubjectiveMetaData from "./examSubjectiveMetaData";
import fileMetaData from "./fileMetaData";
import flowInfoMetaData from "./flowInfoMetaData";
import itemsMetaData from "./itemsMetaData";
import longTextMetaData from "./longTextMetaData";
import mapMetaData from "./mapMetaData";
import navTreeMetaData from "./navTreeMetaData";
import photoMetaData from "./photoMetaData";
import stepsMetaData from "./stepsMetaData";
import subListMetaData from "./subListMetaData";
import systemMetaData from "./systemMetaData";
import textMetaData from "./textMetaData";
import timeIntervalMetaData from "./timeIntervalMetaData";
import timePickerMetaData from "./timePickerMetaData";
import treeInfoMetaData from "./treeInfoMetaData";
import signatureMetaData from "./signatureMetaData";

dsf.metadata.add("big-text-meta-data",bigTextMetaData);
dsf.metadata.add("boolean-meta-data",booleanMetaData);
dsf.metadata.add("customer-img-meta-data",customerImgMetaData);
dsf.metadata.add("data-choice-meta-data",dataChoiceMetaData);
dsf.metadata.add("date-interval-meta-data",dateIntervalMetaData);
dsf.metadata.add("date-picker-meta-data",datePickerMetaData);
dsf.metadata.add("exam-items-meta-data",examItemsMetaData);
dsf.metadata.add("exam-meta-data",examMetaData);
dsf.metadata.add("exam-subjective-meta-data",examSubjectiveMetaData);
dsf.metadata.add("file-meta-data",fileMetaData);
dsf.metadata.add("flow-info-meta-data",flowInfoMetaData);
dsf.metadata.add("items-meta-data",itemsMetaData);
dsf.metadata.add("long-text-meta-data",longTextMetaData);
dsf.metadata.add("map-meta-data",mapMetaData);
dsf.metadata.add("nav-tree-meta-data",navTreeMetaData);
dsf.metadata.add("photo-meta-data",photoMetaData);
dsf.metadata.add("steps-meta-data",stepsMetaData);
dsf.metadata.add("sublist-meta-data",subListMetaData);
dsf.metadata.add("system-meta-data",systemMetaData);
dsf.metadata.add("text-meta-data",textMetaData);
dsf.metadata.add("time-interval-meta-data",timeIntervalMetaData);
dsf.metadata.add("time-picker-meta-data",timePickerMetaData);
dsf.metadata.add("tree-info-meta-data",treeInfoMetaData);
dsf.metadata.add("signature-meta-data",signatureMetaData);