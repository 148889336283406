//引入平台级api
import api from "../api";
//引入平台级元数据
import '../metadata';
//引入平台使用的vuex的store
import store from '../store';
//语言包
import i18n from '../i18n';

!function (global) {
  //注册api
  api(dsf);
  dsf.mix(true, global.$i18n, { ...i18n });
  dsf.mix(true, global.$platformStore, { ...store });
}(dsf.global || (dsf.global = {}))
