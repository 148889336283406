export default function (dsf,options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [
      {
        name: "超大文本",
        code: "img",
        type: dsf.metadata.getDataType("longObject"),
        length: null,
        defaultValue: null,
        unit: null,
        encrypt:false
      },
    ],
  };
}