export default function(dsf,options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [
      {
        name: "时间",
        code: "time",
        type: dsf.metadata.getDataType("time"),
        length: length || 50,
        defaultValue: null,
        unit: null,
        encrypt:false
      }
    ]
  };
}