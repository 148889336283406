/*用于移动端更新keepAlive缓存页面*/
const refreshQueue = {
  namespaced: true,
  state: {
    queue: {}
  },
  mutations: {
    add(state, path) {
      if (!state.queue[path]) {
        state.queue[path] = true;
      }
    },
    remove(state, path) {
      if (state.queue[path]) {
        delete state.queue[path];
      }
    },

  },
  actions: {
  },
  getters: {}
}

export default refreshQueue;