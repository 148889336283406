export default function () {
  return {
    id: "",
    name: "流程信息",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    level: 0,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [
      {
        name: "流程id",
        code: "wf_id",
        type: dsf.metadata.getDataType("string"),
        length: "50",
        defaultValue: null,
        unit: null,
        encrypt:false
      },
      {
        name: "流程名称",
        code: "wf_name",
        type: dsf.metadata.getDataType("string"),
        length: "50",
        defaultValue: null,
        unit: null,
        encrypt:false
      },
    ],
  };
}
