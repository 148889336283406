let socket = null;

/**
 * 所有socket全局订阅
 */
const ws = {
  namespaced: true,
  state: {
    // 菜单角标
    menuSubscript: {},
    // 其他角标
    subscript: {},
    items:{}
  },
  mutations: {
    updateSubscript(state, subscript) {
      let menu = subscript.menu;
      if (menu) {
        delete subscript.menu;
        if (!dsf.isEmptyObject(menu)) {
          state.menuSubscript = {
            ...state.menuSubscript,
            ...menu
          }
        }
      }
      if (!dsf.isEmptyObject(state)) {
        state.subscript = {
          ...state.subscript,
          ...subscript
        };
      }
    }
  },
  actions: {
    init({ commit }) {
      if (socket) return;
      socket = dsf.ws();
      socket.$on('/websocket/subscript', ({data}) => {
        commit('updateSubscript', data);
      });
    }
  },
  getters: {}
}

export default ws;