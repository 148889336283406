export default function(dsf,options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    dataSourceWatch:true,
    validate: dsf.metadata.validate(),
    defaultValue: null,
    dataSource: {
      code: null,
      type: "static",
      attach: [],
    },
    valueAttributes: [
      {
        name: "当前值",
        code: "active",
        type: dsf.metadata.getDataType("number"),
        length: "2,0",
        defaultValue: 0,
        emptyValidate: true,
        encrypt:false,
        unit: null
      },
      {
        name: "走过的最大步骤",
        code: "max",
        type: dsf.metadata.getDataType("number"),
        length: "2,0",
        defaultValue: 0,
        encrypt:false,
        unit: null
      },
      {
        name: "当前状态",
        code: "status",
        type: dsf.metadata.getDataType("string"),
        length: "30",
        defaultValue: "wait",
        encrypt:false,
        unit: null
      },
    ]
  };
}