export default function (dsf) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    level: 0,
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: [
      {
        name: "tree节点父标识",
        code: "pid",
        type: dsf.metadata.getDataType("string"),
        length: "50",
        defaultValue: null,
        encrypt:false,
        unit: null,
      },
      {
        name: "tree节点图标",
        code: "icon",
        type: dsf.metadata.getDataType("string"),
        length: "200",
        defaultValue: null,
        encrypt:false,
        unit: null,
      },
      {
        name: "tree全局标识",
        code: "globalid",
        type: dsf.metadata.getDataType("string"),
        length: "500",
        defaultValue: null,
        encrypt:false,
        unit: null,
      },
      {
        name: "tree节点等级",
        code: "level",
        type: dsf.metadata.getDataType("number"),
        length: "2,0",
        defaultValue: null,
        encrypt:false,
        unit: null,
      },
      {
        name: "tree节点类型",
        code: "type",
        type: dsf.metadata.getDataType("string"),
        length: "10",
        defaultValue: null,
        encrypt:false,
        unit: null,
      },
    ],
  };
}
