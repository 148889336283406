export default function (dsf,options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    isJSONString:true,
    valueAttributes: [
      {
        name: "文件上传",
        code: "file",
        type: dsf.metadata.getDataType("object"),
        length: 500,
        defaultValue: null,
        unit: null,
        encrypt:false
      },
    ],
  };
}
