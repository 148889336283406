export default function(dsf, options){
  return {
    "id": "",
    "name": "",
    "code": "",
    "type": dsf.metadata.mmtype(3),
    "at": "",
    "currentControl": null,
    "controls": [],
    "validate": dsf.metadata.validate(),
    "defaultValue": null,
    "valueAttributes": [{
      "name": "封面设置",
      "code": "conver",
      "type": dsf.metadata.getDataType("object"),
      "length": 500,
      "defaultValue": null,
      "unit": null,
      "encrypt":false
    }]
  };
}
