export default function (dsf,options) {
  return {
    id: "",
    name: "",
    code: "",
    type: dsf.metadata.mmtype(3),
    at: "",
    currentControl: null,
    level: 1,
    controls: [],
    validate: dsf.metadata.validate(),
    defaultValue: null,
    valueAttributes: []
  };
}
