export default function (dsf,options) {
  return {
    "id": "",
    "name": "",
    "code": "",
    "type": dsf.metadata.mmtype(3),
    "at": "",
    "level": 0,
    "currentControl": null,
    "controls": [],
    "validate": dsf.metadata.validate(),
    "defaultValue": null,
    "valueAttributes": [
      {
        "name": "大文本",
        "code": "text",
        "type": dsf.metadata.getDataType("object"),
        "length": "",
        "defaultValue": null,
        "unit": null,
        "encrypt":false
      }
    ]
  }
}