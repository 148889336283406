const printingDesign = {
  namespaced: true,
  state: {
    //整个排座窗口的大小
    compelPanelSize: {
      width: null,
      height: null
    },

    //画布的大小/缩放/位置
    panelSize: {
      width: null,
      height: null,
      scale: 1,
      left: 0,
      top: 0,
    },

    //新增的座位
    newSeatItem: {
      id: null,
      pageX: null,
      pageY: null
    },

    //被激活的 seat/group id
    activeId: ""  ,

    //鼠标框选的信息
    regionPosition: {},

    //seat对应的右侧属性
    seatInfo:{},
    
    //是否有选中的座位(selectedItems.length > 0)
    haveSelectedItems: false,
    
    //是否显示控件的边线框
    showBorderLine: true
    
  },
  getters: {},
  mutations: {
    "changeCompelPanelSize"(state, styObj) {
      state.compelPanelSize = styObj
    },

    "changePanelSize"(state, data){
      state.panelSize = data
    },

    "addSeat2Panel"(state, seat){
      state.newSeatItem = seat
    },

    "changeActiveId"(state, id){
      state.activeId = id;
    },

    "changeRegionPosition"(state, region){
      state.regionPosition = region
    },

    "changeSeatInfo"(state, info){
      state.seatInfo = info
    },
    
    "changeHaveSelectedItems"(state, val){
      state.haveSelectedItems = val
    },
    
    "changeShowBorderLine"(state, val){
      state.showBorderLine = val
    }

  },
  actions: {}
}

export default printingDesign