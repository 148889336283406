export default function(dsf, options) {
  return {
    "id": "",
    "name": "",
    "code": "",
    "type": dsf.metadata.mmtype(2),
    "at": "",
    "currentControl": null,
    "controls": [],
    "level": 0,
    "dataFormatter": "tree",
    "validate": dsf.metadata.validate(),
    "defaultValue": null,
    "valueAttributes": [{
      "name": "节点名称",
      "code": "name",
      "type": dsf.metadata.getDataType("string"),
      "length": "50",
      "defaultValue": null,
      encrypt: false,
      "unit": null
    }, {
      name: "tree节点父标识",
      code: "pid",
      type: dsf.metadata.getDataType("string"),
      length: "50",
      defaultValue: null,
      encrypt: false,
      unit: null,
    },
    {
      name: "tree节点图标",
      code: "icon",
      type: dsf.metadata.getDataType("string"),
      length: "200",
      defaultValue: null,
      encrypt: false,
      unit: null,
    },
    {
      name: "tree全局标识",
      code: "globalid",
      type: dsf.metadata.getDataType("string"),
      length: "500",
      defaultValue: null,
      encrypt: false,
      unit: null,
    },
    {
      name: "tree节点等级",
      code: "level",
      type: dsf.metadata.getDataType("number"),
      length: "2,0",
      defaultValue: null,
      encrypt: false,
      unit: null,
    }
    ]
  }
}